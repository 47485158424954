import React, { useEffect } from "react";
import Helmet from "react-helmet";
import HomepageLayout from "src/layouts/homepage";
import JSONData from "branding/site-metadata.json";
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import footerStyles from 'src/styles/shared/footer.module.scss';
import styles from 'src/styles/shared/legal-pages.module.scss';

export default function Privacy() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <HomepageLayout>
            <Helmet data={JSONData}><title>Privacy Policy</title></Helmet>
            <div className={styles.legalPage}>
                <header>
                    <img src="/img/mainlogo.png" alt="Main Logo" />
                </header>
                <div className={styles.container}>
                    <div
                        name="termly-embed"
                        data-id="0d21ce7c-3798-470d-9d45-e1ae2d2d206b"
                        data-type="iframe"
                    ></div>
                </div>
            </div>
            <RawFooter />
        </HomepageLayout>
    );
}
